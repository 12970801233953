import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectPropTwo from "./itemProp/ProjectPropTwo";
import ProjectData from "../../data/project/ProjectData.json";
import { Link } from "react-router-dom";

const portfolioData = ProjectData;

const ProjectFour = () => {
  return (
    <div className="section section-padding-equal bg-color-dark">
      <div className="container">
        <SectionTitle
          title="Cómo funciona"
          subtitle="Generar tu chatbot es más fácil de lo que parece"
          textAlignment="heading-light-left mb--90"
          textColor=""
        />

        <div className="row row-45">
          {portfolioData.slice(12, 16).map((data, index) => (
            <div className="col-md-6" key={data.id}>
              <ProjectPropTwo
                projectStyle="project-style-2"
                portfolio={data}
                i={index}
              />
            </div>
          ))}
        </div>
        <div className="more-project-btn">
          <Link
            to={process.env.PUBLIC_URL + "/project-grid-two"}
            className="axil-btn btn-fill-white"
          >
            Contacta un asesor
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectFour;
