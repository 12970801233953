import React from "react";
import Dropin from "../../assets/img/dropin.png";
import Utilia from "../../assets/img/utilia.png";
import Grainger from "../../assets/img/grainger.png";
import Bitam from "../../assets/img/bitam.png";

const Data = [
  {
    image: Dropin,
  },
  {
    image: Utilia,
  },
  {
    image: Grainger,
  },
  {
    image: Bitam,
  },
  //   {
  //     image: "/images/brand/brand-5.png",
  //   },
  //   {
  //     image: "/images/brand/brand-6.png",
  //   },
  //   {
  //     image: "/images/brand/brand-7.png",
  //   },
  //   {
  //     image: "/images/brand/brand-8.png",
  //   },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-3 col-6" key={index}>
          <div className="brand-grid">
            <img
              src={process.env.PUBLIC_URL + data.image}
              alt="Brand"
              width={300}
              style={{ margin: "0 20px" }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
