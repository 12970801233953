import React from "react";
import { Link } from "react-router-dom";
// import { slugify } from "../../../utils";
import ImgOne from "../../../assets/img/1.png";
import ImgTwo from "../../../assets/img/2.png";
import ImgThree from "../../../assets/img/3.png";
import ImgFour from "../../../assets/img/chatbotgif.gif";

const imgs = [ImgOne, ImgTwo, ImgThree, ImgFour];

const PropOne = ({ projectStyle, portfolio, i }) => {
  return (
    <>
      <div className={`project-grid ${projectStyle}`}>
        <div className="thumbnail">
          <Link
          // to={
          //   process.env.PUBLIC_URL +
          //   `/project-details/${slugify(portfolio.title)}`
          // }
          >
            <img src={imgs[i]} alt="icon" />
          </Link>
        </div>
        <div className="content">
          <h3 className="title">
            <Link
            //   to={
            //     process.env.PUBLIC_URL +
            //     `/project-details/${slugify(portfolio.title)}`
            //   }
            >
              {portfolio.title}
            </Link>
          </h3>
        </div>
      </div>
    </>
  );
};

export default PropOne;
